
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
    routes: [{
        path: "/",
        name: "HelloWorld",
        meta: {
            title: "首页",
        },
        component: () =>
            import("@/components/HelloWorld.vue")
    }, {
        path: "/first",
        name: "first",
        meta: {
            title: "第一题",
        },
        component: () =>
            import("@/components/first.vue")
    }, {
        path: "/second",
        name: "second",
        meta: {
            title: "第二题",
        },
        component: () =>
            import("@/components/second.vue")
    }, {
        path: "/third",
        name: "third",
        meta: {
            title: "第三题",
        },
        component: () =>
            import("@/components/third.vue")
    }, {
        path: "/fourth",
        name: "fourth",
        meta: {
            title: "第四题",
        },
        component: () =>
            import("@/components/fourth.vue")
    },
    ]
});