<template>
  <div class="contain">
    <button type="button" @click="go">返回</button>
  </div>
</template>

<script>
export default {
  name: "returnComponent",
  data() {
    return {};
  },
  methods: {
    go() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.contain {
  height: 40px;
  width: 100%;
  text-align:left;
}
</style>
